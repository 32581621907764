import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import SelectAuction from './SelectAuction';
import BiddingMethod from './BiddingMethod';
import './step.scss';
import {
  filterAuctionsByBiddingMethod,
  formatAuctions,
  getConditionsOfSale,
} from '../register-to-bid.utils';
import { steps } from 'utils/registrationStepsConstants';
import Footer from '../Footer';
import { BIDDING_METHODS } from 'utils/auctionTypes';
import { computePrice } from '../checkout';

const AuctionSelectionStep = ({
  values,
  setFieldValue,
  auctions,
  setStep,
  isValid,
  dispatchChevronStep,
  checkPersonalDateOutdatedHandler,
  dispatchPrevSteps,
  checkFinancialDateOutdatedHandler,
  isKiosk,
  handleStartOverButton,
  bidderSalesforceId,
  setIsPersonalBidding,
  setChosenBiddingMethod,
  activeSignedTermsAndConditions,
  handleSubmit,
  changeRegion,
}) => {
  const setAuction = auction => {
    changeRegion(getConditionsOfSale([auction], auctions));
    if (!values.biddingMethod) return;
    _.isFunction(setFieldValue) &&
      setFieldValue('auction', _.xor(values.auction, [auction]));
  };

  const setBiddingMethod = biddingMethod => {
    values.auction = [];
    _.isFunction(setFieldValue) &&
      setFieldValue('biddingMethod', biddingMethod);
    if (
      biddingMethod === BIDDING_METHODS.IN_PERSON_BIDDING ||
      biddingMethod === BIDDING_METHODS.TELEPHONE_BIDDING
    ) {
      setIsPersonalBidding(true);
      setChosenBiddingMethod(biddingMethod);
    } else {
      setIsPersonalBidding(false);
    }
  };

  const formattedAuctions = formatAuctions(auctions);
  const filteredByBiddingMethod = filterAuctionsByBiddingMethod(
    formattedAuctions,
    values.buyerStatus
  );

  async function submit() {
    if (!isValid) return;
    values['conditionsOfSale'] = getConditionsOfSale(values.auction, auctions);
    checkPersonalDateOutdatedHandler(values.auction);
    checkFinancialDateOutdatedHandler(values.auction);
    transitionToNextStep();
  }

  async function transitionToNextStep() {
    dispatchChevronStep('next');
    //check for active signed terms
    if (activeSignedTermsAndConditions) {
      // if (values.biddingMethod === BIDDING_METHODS.IN_PERSON_BIDDING) {
      //   if (isKiosk) {
      //     const { price } = computePrice(values, auctions);
      //     if (price) setStep(steps.KIOSK_PAYMENT);
      //     else await handleSubmit(values);
      //   } else {
      //     setStep(steps.PAYMENT);
      //   }
      // } else if (values.biddingMethod === BIDDING_METHODS.TELEPHONE_BIDDING)
      //   setStep(steps.TELEPHONE_BIDDING_DETAILS);
      // else {
      setStep(steps.FINANCIAL_DATA);
      // }
    } else {
      setStep(steps.CONDITION_OF_SALE);
    }
    dispatchPrevSteps({ type: 'push', payload: steps.AUCTIONS_SELECTION });
  }

  function transitionToPrevStep() {
    dispatchChevronStep('back');
    const lastStep = dispatchPrevSteps({ type: 'pop' });
    setIsPersonalBidding(false);
    setStep(lastStep);
  }
  return (
    <Fragment>
      <h1 className="heading">Select Bidding Method &amp; Auctions</h1>

      <BiddingMethod
        selected={values.biddingMethod}
        handleSelect={setBiddingMethod}
        auctions={filteredByBiddingMethod}
        values={values}
        isKiosk={isKiosk}
      />

      <SelectAuction
        visible={!!values.biddingMethod}
        values={values.auction}
        biddingMethod={values.biddingMethod}
        handleSelect={setAuction}
        auctions={formattedAuctions ?? []}
        buyerStatus={values.buyerStatus}
        bidderSalesforceId={bidderSalesforceId}
        isKiosk={isKiosk}
        length={auctions.length}
      />
      <Footer
        handleNext={() => submit()}
        handleBack={() => transitionToPrevStep()}
        isValid={isValid}
        isKiosk={isKiosk}
        submitButtonText="Next"
        handleStartOverButton={handleStartOverButton}
      />
    </Fragment>
  );
};

AuctionSelectionStep.propTypes = {
  setFieldValue: PropTypes.func,
  setAuction: PropTypes.func,
  setBiddingMethod: PropTypes.func,
  values: PropTypes.shape({
    biddingMethod: PropTypes.string,
  }),
};

export default AuctionSelectionStep;
