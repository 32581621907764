import React, { useEffect, useRef, useState } from 'react';
import TextInput from 'components/form-elements/TextInput';
import Select from 'components/form-elements/Select';
import styles from '../CheckoutForms.module.scss';
import { useCountriesStates } from '../../../shared/hooks/countries';

const BillingInformation = ({
  country,
  state,
  setFieldValue,
  removeTitle,
  removeName,
  removeEmail,
  fieldsNames,
  shippingAddressRef,
  stripeRef,
  handleDoneClick,
  isUk,
}) => {
  const fieldsNamesInitial = fieldsNames;
  const [countries, states] = useCountriesStates(
    country,
    state,
    setFieldValue,
    fieldsNamesInitial.country,
    fieldsNamesInitial.state
  );
  const zipcodeRef = useRef(null);
  return (
    <div className={styles.sectionWrapper}>
      {!removeTitle && <h2 className={styles.title}>Billing Information</h2>}
      {!removeName && (
        <div className={`${styles.fieldGroup}`}>
          <div className={`${styles.field}`}>
            <TextInput
              name={fieldsNamesInitial.firstName}
              placeholder="First Name&#42;"
              onKeyDown={handleDoneClick}
            />
          </div>
          <div className={`${styles.field}`}>
            <TextInput
              name={fieldsNamesInitial.lastName}
              placeholder="Last Name&#42;"
              onKeyDown={handleDoneClick}
            />
          </div>
        </div>
      )}

      {!removeEmail && (
        <div className={`${styles.field}`}>
          <TextInput
            name={fieldsNamesInitial.email}
            placeholder="Email&#42;"
            onKeyDown={handleDoneClick}
          />
        </div>
      )}

      <div className={`${styles.field}`}>
        <TextInput
          name={fieldsNamesInitial.addressName1}
          placeholder="Address 1&#42;"
          onKeyDown={handleDoneClick}
          textRef={shippingAddressRef}
        />
      </div>

      {!isUk && (
        <div className={`${styles.field}`}>
          <TextInput
            name={fieldsNamesInitial.addressName2}
            placeholder="Address 2"
            onKeyDown={handleDoneClick}
          />
        </div>
      )}
      <div className={`${styles.fieldGroupThreeChildren}`}>
        <div className={`${styles.field}`}>
          <TextInput
            name={fieldsNamesInitial.city}
            placeholder="City&#42;"
            onKeyDown={handleDoneClick}
          />
        </div>
        <div className={`${styles.fieldGroup}`}>
          <div className={`${styles.field}`}>
            <Select
              name={fieldsNamesInitial.state}
              data={states}
              placeholder="State&#42;"
              onKeyDown={event => {
                if (
                  event.key.toLowerCase() === 'enter' ||
                  event.keyCode === 13
                ) {
                  zipcodeRef.current.children[0].children[0].focus();
                }
              }}
            />
          </div>
          <div className={`${styles.field}`}>
            <TextInput
              name={fieldsNamesInitial.postalCode}
              placeholder={`${isUk ? 'PostalCode*' : 'Zipcode*'}`}
              textRef={zipcodeRef}
              onKeyDown={handleDoneClick}
            />
          </div>
        </div>
      </div>

      <div className={`${styles.fieldGroup}`}>
        <div className={`${styles.field}`}>
          <Select
            name={fieldsNamesInitial.country}
            data={countries}
            placeholder="Country&#42;"
            onChangeHandler={() => {
              setFieldValue(fieldsNamesInitial.state, '');
              setFieldValue(fieldsNamesInitial.city, '');
              setFieldValue(fieldsNamesInitial.postalCode, '');
            }}
            onKeyDown={event => {
              if (event.key.toLowerCase() === 'enter' || event.keyCode === 13) {
                if (stripeRef && stripeRef.current) {
                  stripeRef.current.children[0].children[0].focus();
                } else {
                  shippingAddressRef.current.children[0].children[0].focus();
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

BillingInformation.defaultProps = {
  removeTitle: false,
  removeName: false,
  removeEmail: false,
  fieldsNames: {
    firstName: 'firstName',
    lastName: 'lastName',
    addressName1: 'line1',
    addressName2: 'line2',
    city: 'city',
    state: 'state',
    postalCode: 'postalCode',
    country: 'country',
    email: 'receiptEmail',
  },
};

export default BillingInformation;
