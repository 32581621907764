import React, { useRef, useState } from 'react';
import FileInput from 'components/form-elements/FileInput';
import TextInput from 'components/form-elements/TextInput';
import NumberInput from 'components/form-elements/NumberInput';
import CheckboxInput from 'components/form-elements/CheckboxInput';
import FileUpload from 'features/onlineRegistration/FileUpload';
import PDF from 'static/pdf/GCo_Bank_Letter_sample.pdf';
import './step.scss';
import Footer from '../Footer';
import { steps } from 'utils/registrationStepsConstants';
import { BIDDING_METHODS } from 'src/utils/auctionTypes';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { SLUGS } from 'utils/webPageTypesConstants';
import { SEND_SUBSCRIPTION_EMAIL } from 'services/graphql/queries/vehicles-catalogue';
import Spinner from 'components/Spinner';
import { useMutation } from '@apollo/client';

export default ({
  setFieldValue,
  values,
  handleSubmit,
  isValid,
  setStep,
  dispatchChevronStep,
  setFieldTouched,
  dispatchPrevSteps,
  prevSteps,
}) => {
  const bankLetterRef = useRef(null);
  const slugs = useWebPagesSlugs();
  const [file, updateFile] = useState(values.bankLetterFile);
  const [
    sendEmail,
    { error: emailError, loading: isSendingEmail },
  ] = useMutation(SEND_SUBSCRIPTION_EMAIL);
  async function submit() {
    if (!isValid) return;
    await transitionToNextStep();
  }
  async function transitionToNextStep() {
    if (values.subscribe === true) {
      const name = values?.firstName + ' ' + values?.lastName;
      const fields = {
        title: `Join Mailing List - ${
          name?.charAt(0).toUpperCase() + name?.slice(1)
        }`,
        name: name?.charAt(0).toUpperCase() + name?.slice(1),
        email: values?.email,
      };
      await sendEmail({
        variables: {
          fields,
        },
      });
    }
    if (!emailError) {
      dispatchPrevSteps({ type: 'push', payload: steps.FINANCIAL_DATA });
      if (values.biddingMethod === BIDDING_METHODS.TELEPHONE_BIDDING) {
        dispatchChevronStep('next');
        setStep(steps.TELEPHONE_BIDDING_DETAILS);
      } else if (values.biddingMethod === BIDDING_METHODS.IN_PERSON_BIDDING) {
        dispatchChevronStep('next');
        setStep(steps.PAYMENT);
      } else {
        await handleSubmit(values);
      }
    }
  }

  function transitionToPrevStep() {
    dispatchChevronStep('back');
    const prevStep = dispatchPrevSteps({ type: 'pop' });
    setStep(prevStep);
  }

  return (
    <>
      <div className="step-5" id="formId">
        {isSendingEmail && <Spinner />}
        <div className="section">
          <h1 className="heading">Financial References</h1>
          <div className="text-subdued">
            <p className="steps-hint">
              If this is your first time bidding with Gooding & Company or you
              have not bid with us recently, we require a financial reference
              prior to approving your registration and issuing a bidder paddle.
              An original Bank Letter of Guarantee or other proof of ability to
              pay for purchases, in an amount and in a form acceptable to
              Gooding in its sole discretion, must be included to finalize your
              registration.
            </p>
          </div>
          <h3 className="sub-heading">Bank Letter of Guarantee&#42;</h3>
          <div className="text-subdued">
            <p>
              An original Bank Letter of Guarantee or other proof of ability to
              pay for purchases, in an amount and in a form acceptable to
              Gooding in its sole discretion, must be included to finalize your
              registration.
            </p>
          </div>
        </div>

        <div className="section">
          <a
            className="view-letter-link"
            href={PDF}
            target="_blank"
            rel="noreferrer"
          >
            View Sample Bank Letter
          </a>
        </div>

        <div className="section">
          <div className="field-group choose-file">
            <div className="field">
              <FileUpload
                browseFile={() => bankLetterRef.current.click()}
                setValue={value => {
                  setFieldValue('bankLetterFile', value);
                  setFieldTouched('bankLetterFile', true);
                  updateFile(value);
                }}
              />
              <FileInput
                name="bankLetterFile"
                forwardedRef={bankLetterRef}
                acceptFiles="image/png,image/jpeg,application/pdf"
                errorMsg="File type must be .PNG, .JPG or .PDF"
                clickHandler={value => {
                  setFieldTouched('bankLetterFile', true);
                }}
                file={file}
                updateFile={updateFile}
              />
            </div>
          </div>
        </div>

        <div className="section">
          <h3 className="sub-heading">Bank Information</h3>
          <div className="field-group three">
            <div className="field">
              <TextInput name="bankName" placeholder="Bank Name and Branch" />
            </div>
            <div className="field">
              <TextInput
                name="bankStreetAddress"
                placeholder="Bank Street Address"
              />
            </div>
            <div className="field">
              <TextInput name="bankPhone" placeholder="Bank Telephone Number" />
            </div>
          </div>
          <div className="field-group three">
            <div className="field">
              <TextInput
                name="bankZipcode"
                placeholder="Bank ZIP/Postal Code"
              />
            </div>
            <div className="field">
              <TextInput
                name="bankBranchOffice"
                placeholder="Branch Office / Account Officer"
              />
            </div>
            <div className="field">
              <NumberInput
                name="biddingLimits"
                placeholder="Requested Bidding Limits ($USD)&#42;"
              />
            </div>
          </div>
          <div className="field-group">
            <div className="terms-wrapper">
              <CheckboxInput
                name="acceptFinancialTerms"
                handleSelect={() => {}}
              />

              <label className="text-subdued" htmlFor="acceptFinancialTerms">
                I expressly authorize Gooding to use my financial information
                for the purposes of verifying available credit for purchases at
                auction, including by contacting my financial institution and/or
                card issuer to verify account balances and available credit.
                This information will be processed in accordance with the{' '}
                <a
                  href={`/${slugs[SLUGS.privacyPolicy]}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>
      <Footer
        handleNext={() => submit()}
        disableBackButton={prevSteps.includes('payment')}
        handleBack={() => transitionToPrevStep()}
        isValid={isValid}
        submitButtonText={
          values.biddingMethod === BIDDING_METHODS.ONLINE_BIDDING
            ? 'Submit'
            : 'Next'
        }
      />
    </>
  );
};
