import React from 'react';
import TextInput from 'components/form-elements/TextInput';
import Select from 'components/form-elements/Select';
import { useCountriesStates } from 'shared/hooks/countries';
import styles from './profileForm.module.scss';

const LocationInformation = ({
  country,
  state,
  setFieldValue,
  title,
  placeholderPrefix,
  fieldsNames,
}) => {
  const [countries, states] = useCountriesStates(
    country,
    state,
    setFieldValue,
    fieldsNames.country,
    fieldsNames.state
  );

  return (
    <div className={styles.formSection}>
      <h2 className={styles.subHeading}>{title}</h2>
      <div className={`row`}>
        <div className={`${styles.formSelect} col-md-12`}>
          <TextInput
            name={fieldsNames.address1}
            placeholder={`${placeholderPrefix} Address 1*`}
          />
        </div>
      </div>

      <div className="row">
        <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
          <TextInput
            name={fieldsNames.address2}
            placeholder={`${placeholderPrefix} Address 2`}
          />
        </div>
        <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
          <TextInput
            name={fieldsNames.city}
            placeholder={`${placeholderPrefix} City*`}
          />
        </div>
      </div>

      <div className="row">
        <div className={`${styles.webViewZipCode} col-sm-12 col-md-6`}>
          <div className="row">
            <div className={`${styles.formSelect} col-6`}>
              <Select
                name={fieldsNames.state}
                data={states}
                placeholder={`${placeholderPrefix} State*`}
                ariaLabel="state"
              />
            </div>
            <div className={`${styles.formSelect} col-6`}>
              <TextInput
                name={fieldsNames.zipcode}
                placeholder={`${placeholderPrefix} Zipcode*`}
              />
            </div>
          </div>
        </div>
        <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
          <Select
            name={fieldsNames.country}
            ariaLabel="country"
            data={countries}
            placeholder={`${placeholderPrefix} Country*`}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationInformation;
