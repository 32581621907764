import React, { useState, useEffect } from 'react';
import NumberInput from 'components/form-elements/NumberInput';
import AddAnother from './addAnother';
import VehiclesSearch from './VehicleSearch';

const VehicleList = ({
  name,
  index,
  setFieldValue,
  lots,
  values,
  vehicleInputsRef,
}) => {
  const [flag, setFlag] = useState(false);
  const setBiddingForm = () => {
    setFlag(true);
  };
  const [mappedLots, setMappedLots] = useState(mapLots(lots));

  useEffect(() => {
    if (lots) setMappedLots(mapLots(lots));
  }, [lots]);
  const getLotInfo = (lotNumber, name) => {
    getDescriptionForLotNumber(lotNumber, name.substring(9, name.length));
  };
  const getDescriptionForLotNumber = (lotNumber, index) => {
    const flitteredLots = lots.filter(
      lot => parseInt(lot.lotNumber) === parseInt(lotNumber)
    );
    if (flitteredLots.length > 0) {
      const lot = flitteredLots[0];
      if (lot.make) {
        setFieldValue(
          `description${index}`,
          `${lot.modelYear} ${lot.make} ${lot.model}`
        );
      } else {
        setFieldValue(`description${index}`, `${lot.title}`);
      }
    } else {
      setFieldValue(`description${index}`, '');
    }
  };
  return (
    <div key={index}>
      <h2 className="sub-heading my-0">{name}</h2>
      <br />
      <div className="field" ref={vehicleInputsRef}>
        <NumberInput
          name={`lotNumber${index}`}
          format="##########"
          placeholder="Lot Number"
          handler={getLotInfo}
        />
      </div>
      <div className="field">
        <VehiclesSearch
          options={mappedLots}
          placeholder="Description (Year, Make, Model)"
          fieldName={`description${index}`}
          lotFieldName={`lotNumber${index}`}
          setFieldValue={setFieldValue}
          values={values}
        />
      </div>
      <p className="text-subdued">Note: Does not include buyer’s premium.</p>
      <div className="field">
        <NumberInput
          name={`maximumBid${index}`}
          format="##########"
          placeholder="Maximum Bid"
        />
      </div>
      {flag === true && (
        <AddAnother
          index={index}
          handler={getLotInfo}
          lots={mappedLots}
          setFieldValue={setFieldValue}
          values={values}
        />
      )}

      {flag === false && (
        <h4
          onClick={setBiddingForm}
          className="addAnotherVehicle cursor-pointer"
          role="presentation"
        >
          + Add Another Vehicle
        </h4>
      )}
    </div>
  );
};
export default VehicleList;

const mapLots = lots => {
  return lots.map(lot => {
    return {
      id: lot.objectID,
      title: lot.make ? `${lot.modelYear} ${lot.make} ${lot.model}` : lot.title,
      lotNumber: lot.lotNumber,
    };
  });
};
